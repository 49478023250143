import * as PIXI from 'pixi.js';
import GameScreen from "./view/screens/GameScreen";
import ResourceService from "./services/ResourceService";
import GameModel, { EGameStates } from './model/GameModel';
import { AppConfig } from './config/AppConfig';
import Fireworks from './view/components/effects/Fireworks';
import EMessages from './services/EMessages';
import gsap from 'gsap';
import GAME_CONFIG from './config/GameConfig';

class Game extends PIXI.Container {
    constructor(app) {
        super();
        this.app = app;
        this.gameModel = new GameModel();

        this.onGameStateUpdated = () => {
            if (this.gameModel.gameState === EGameStates.playing){
                // this.app.ticker.start();
            }
            if (this.gameModel.gameState === EGameStates.stop) {
                // this.app.ticker.stop();
            };
        };

        this.gameModel.gameStateUpdated.add(this.onGameStateUpdated);


        ResourceService.init(() => {
            this.clerPreloader();
            this.gameModel.sendMessage(EMessages.SND_ALL_LOADED);

            const preloader = document.getElementById('preloader');

            gsap.to(preloader, {
              y: '-100%',
              duration: 1,
              ease: 'power3.inOut',
              onComplete: () => {
                preloader.remove();
            },
            });


            this.startApp();
            this.animate();
        });

        this.animate = (delta) => {
            // if (this.gameModel.gameState !== EGameStates.playing) return
            requestAnimationFrame(this.animate);
            this.app.renderer.render(this.app.stage);
            this.gameScreen?.animate(delta);

        };
    }

    startApp() {
        this.cleanUp();
        this.gameScreen = new GameScreen(this.app, this.gameModel);
        this.addChild(this.gameScreen);
    };

  

    cleanUp() {
        this.removeChildren();
    };

    clerPreloader() {
        if (window.preloaderInterval) {
            clearInterval(window.preloaderInterval);
        }
    }


}

export default Game;
