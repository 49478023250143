import { init} from "./init";
import packageJSON from '../package.json';

window.appversion = packageJSON.version;
document.title = `${packageJSON.description} v${packageJSON.version}`;

const game = init();


// const bannerIMG = document.querySelector('#banner');






