class DebugService {}

/**
 * 
 * @param {string | object} message 
 * @param {("event" | "gameplay" | "")} str 
 */
DebugService.log = (message, type = "") => {


    const reportMessages = document.querySelector('#logMessages');
    let reportStr = "";
    if (typeof message === 'object') {
        reportStr = JSON.stringify(message);
        reportMessages.innerHTML = reportStr.replaceAll( ",", ",\n");
    } else {

        reportMessages.innerHTML = "" + message;
    }

}

/**
 * 
 * @param {string} value 
 */
DebugService.logFPS = (value) => {
    const logFPS = document.querySelector('#logFPS');
    logFPS.innerHTML = "" + value;
}



export default DebugService