import * as PIXI from 'pixi.js';
import { Text } from 'pixi.js';
import gsap from "gsap/all";
import { AppConfig } from '../../config/AppConfig';
import GameModel from '../../model/GameModel';

class ScoreBallon extends PIXI.Sprite {
        /**
     * @param {*} item
     * @param {( 'scores' | 'time' | 'speedUp' | 'magnet' | 'steakMultiplier')} type
     * @param { x:number, y:number } point
     * @param { -1 | 0 | 1 } pos
     */
    constructor(type, scores, point, pos, kind){
        super();

          const kindness = kind?.kindness;

        this.type = type;
        this.pos = pos;
        this.distanceY = 200;
        this.fontSize = 19;
        this.angleSet = (Math.PI / 8);
        let scoreCaption;
        let textColor = [0xfff046, 0xF4B940];
        this.duration = 1.5;
        this.dropShadowColor = '#20023B';
        this.stroke = '#4e0085';
        let prefix = "";


        switch(type) {
            case 'scores':
              if (kindness === 'good') {
                prefix ='+';
                scoreCaption = prefix + scores;
              }
      else{
                textColor = [0xFBE3A2, 0xF5B865];
                prefix = "¡OUCH!\n";
                scoreCaption = prefix +scores;
                this.fontSize = 12;
              this.dropShadowColor = '#A00808';
                this.stroke = '#E8231B';
                // this.distanceY = -40; //they are not noticible
                this.angleSet = -(Math.PI / 8)
              }
                break;
            case  'time':
              if (kindness === 'good') {
                prefix ="¡SÚPER!\n+";
                scoreCaption = prefix + scores + 's';
              }
      else{
                textColor = [0xFBE3A2, 0xF5B865];
                prefix = "¡OUCH!\n";
                scoreCaption = prefix + scores + 's';
                this.fontSize = 12;
                this.dropShadowColor = '#A00808';
                this.stroke = '#E8231B';
                // this.distanceY = -40;
                this.angleSet = -(Math.PI / 8)
              }
                break;
            case 'speedUp':
                textColor = [0xfff046, 0xF4B940];
                scoreCaption = "¡BUENA!";
                this.duration = 2;
                break;
            case 'steakMultiplier':
                textColor = [0xfff046, 0xF4B940];
                scoreCaption = "x" + scores;
                this.duration = 2;
                this.distanceY = 50;
                this.fontSize = 30;
                break;
            case 'magnet':
                textColor = [0xfff046, 0xF4B940];
                scoreCaption = "¡INCREÍBLE!";
                this.duration = 2;
                break;
            default:
                textColor = [0xfff046, 0xF4B940];
                scoreCaption = scores >= 0 ? "+" + scores : scores;
        }
        this.scoresTextShadow = new Text(scoreCaption, {
            fontFamily: 'MainBasketRun',
            fontWeight: 'bold',
            fontSize: this.fontSize,
            fill: 0x4e0085,
            align: 'center',
            stroke: 'white',
            strokeThickness: 5
        });
        this.scoresTextShadow.visible = false;
        this.scoresText = new Text(scoreCaption, {
            fontFamily: 'MainBasketRun',
            fontWeight: 'bold',
            fontSize: this.fontSize,
            fill: textColor,
          fillGradientStops: [0.5, 1],
            stroke: '#4e0085',
            strokeThickness: 3,
            align: 'center',
          dropShadow: true,
          dropShadowColor: '#20023B',
          dropShadowDistance: 2,
          dropShadowBlur: 0,
          dropShadowAngle: Math.PI / 3,
        });

        this.x = point.x;
        this.y = point.y;
        this.alpha = 1;
        this.anchor.set(0.5, 0.5);
        this.scoresText.anchor.set (0.5, 0.5);
        this.scoresTextShadow.anchor.set (0.5, 0.5);

        this.scoresTextShadow.x = 2;
        this.scoresTextShadow.y = 3;


        this.addChild(this.scoresTextShadow);
        this.addChild(this.scoresText);
        this.rotation =  this.pos * (Math.PI / 12),
        this.startTween();
    }

    startTween() {
      const {_speed: speed, _speedUpFactor: speedUpFactor} = AppConfig.game.gameModel;
        const { gameWidth } = AppConfig.settings;
        const center = gameWidth / 2;
        let targetX = this.x;
        if (this.pos === -1) targetX = this.x - gameWidth / 12;
        if (this.pos === 1) targetX = this.x + gameWidth / 12;

        const duration = this.duration * (speed / speedUpFactor);

        const tween = gsap.timeline({paused: true});
      tween.to(this.scale, { x: 2, y: 2, duration: 0.2, stagger: 0.2 }, 0)
            .to(this, {
              y: this.y - this.distanceY,
              x: targetX,
              rotation: this.pos * this.angleSet,
              ease: "power2.out",
                duration,
              onComplete: () => {
                this.emit('finish');
            }
            }, 0);
      tween.to(this, {
        alpha: 0,
        ease: 'power2.in',
        duration: duration * 0.4
      }, duration * 0.6)

      tween.play()
    }


}

export default ScoreBallon
