
import { MiniSignal } from "mini-signals";

class AppConfig {

    static sizeUpdated = new MiniSignal();
    static isDebug = false;

    /** 
     * @readonly
     */
    static settings = {
        gameWidth: 600,
        gameHeight: 800
    }

    static updateSize (w, h) {
        AppConfig.settings.gameWidth = w;
        AppConfig.settings.gameHeight = h;
        AppConfig.sizeUpdated.dispatch();
    }

    /** 
     * @readonly
     */
    static settings3D = {
        itemsExtraScale: 0.9,
        focalLength: 10,
        scaleZoom: 26,
        horyzontPos: 0.2,
        animationSped: 1 * 5,
        worldSize: 1000,
        conveyorY: 12,
        conveyorWidth: 6,
        zCartPosition: 10,
        zDeep:100,
        zAppeared: 70
    };

    /** 
     * @readonly
     */
    static gameSettings = {
        levelMaxScores: 500,
        cartWidth: 50,
        newItemDelay: 1 * 1000,
        magnetItemsCount: 100,
        magnetMaxDuration: 3000, //we double duration of bugnet by timeout and itemscount
        speedUpDuration: 3000,
        timeMax: 180,
        initialTimeLeft: 60,
        initialSpeed: 1,
        counDownStepDuration:0.5,
        maxEmptyRows: 3
    };

    /** 
     * @readonly
     */
    static animationSettings = {
        itemJumpDuration: 0.3,
        itemDropDuration: 0.6,
        displayItemsInCart: 30
    };

    /** 
     * @readonly
     */
        static particleSettings = {
        };
}

export { AppConfig };