class ResourceList {
}
ResourceList.BG = `./assets/bg.jpg`;
ResourceList.LOGO = `./assets/logo.png`;
ResourceList.RULES = `./assets/rules.png`;
ResourceList.CART = `./assets/cart.png`;
ResourceList.CART_OVER = `./assets/cart-over.png`;
ResourceList.START_BUTTON = `./assets/start-button.png`;
ResourceList.HELLO_TITLE = `./assets/hello-title.png`;
ResourceList.GOOD_1 = `./assets/good_1.png`;
ResourceList.GOOD_2 = `./assets/good_2.png`;
ResourceList.GOOD_3 = `./assets/good_3.png`;
ResourceList.GOOD_4 = `./assets/good_4.png`;;
ResourceList.BAD_1 = `./assets/bad_1.png`;

ResourceList.SHELVES_PRODUCTS_BL = `./assets/bg/shelves_products_bl.png`;
ResourceList.SHELVES_PRODUCTS_BR = `./assets/bg/shelves_products_br.png`;
ResourceList.SHELVES_PRODUCTS_ML = `./assets/bg/shelves_products_ml.png`;
ResourceList.SHELVES_PRODUCTS_MR = `./assets/bg/shelves_products_mr.png`;
ResourceList.SHELVES_PRODUCTS_TL = `./assets/bg/shelves_products_tl.png`;
ResourceList.SHELVES_PRODUCTS_TR = `./assets/bg/shelves_products_tr.png`;
ResourceList.SHELVE_BL = `./assets/bg/shelve_bl.png`;
ResourceList.SHELVE_BR = `./assets/bg/shelve_br.png`;
ResourceList.SHELVE_ML = `./assets/bg/shelve_ml.png`;
ResourceList.SHELVE_MR = `./assets/bg/shelve_mr.png`;
ResourceList.SHELVE_TL = `./assets/bg/shelve_tl.png`;
ResourceList.SHELVE_TR = `./assets/bg/shelve_tr.png`;
ResourceList.WALL = `./assets/bg/wall.png`;
ResourceList.DOOR = `./assets/bg/door.png`;
ResourceList.CONVEYOR = `./assets/bg/conveyor.png`;
ResourceList.CONVEYOR_LIGHT_LEFT = `./assets/bg/conveyor-light_left.png`;

ResourceList.ITEM_MAGNET = `./assets/item-magnet.png`;
ResourceList.ITEM_SPEEDUP = `./assets/item-speedup.png`;
ResourceList.ITEM_TIMEPLUS = `./assets/item-timeplus.png`;
ResourceList.ITEM_TIMEMINUS = `./assets/item-timeplus.png`;


ResourceList.ITEM_SPEEDUP = `./assets/products/lighting.png`;
ResourceList.ITEM_TIMEPLUS = `./assets/products/watch-plus.png`;
ResourceList.ITEM_TIMEMINUS = `./assets/products/watch-minus.png`;

ResourceList.TIMER_BASE = `./assets/timer-base.png`;
ResourceList.TIMER_PROGRESS_MIDDLE = `./assets/timer-progress-middle.png`;
ResourceList.TIMER_PROGRESS_RIGHT = `./assets/timer-progress-right.png`;
ResourceList.TIMER_PROGRESS_ARROW = `./assets/timer-arrow.png`;

ResourceList.PRGS_CAPSULE_BG = `./assets/progress/capsule-bg.png`;
ResourceList.PRGS_CAPSULE_FLARE = `./assets/progress/capsule-flare.png`;
ResourceList.PRGS_CAPSULE_SHADOW = `./assets/progress/capsule-shadow.png`;
ResourceList.PRGS_DISK_EXTRA_MAGNET = `./assets/progress/disk-extra-magnet.png`;
ResourceList.PRGS_DISK_EXTRA_SPEEDUP = `./assets/progress/disk-extra-speedup.png`;
ResourceList.PRGS_DISK_TIMER = `./assets/progress/disk-timer.png`;

ResourceList.MSC_INFOBOX_BG = `./assets/misc/infobox-bg.png`;
ResourceList.MSC_COIN = `./assets/misc/coin.png`;
ResourceList.MSC_STAR = `./assets/misc/star.png`;
ResourceList.MSC_MINUS = `./assets/misc/minus.png`;
ResourceList.MSC_PLUS = `./assets/misc/plus.png`;
ResourceList.MSC_STAR4 = `./assets/misc/star4.png`;
ResourceList.MSC_DROP = `./assets/misc/drop.png`;
ResourceList.MSC_SHIMMER = `./assets/misc/shimmer.png`;
ResourceList.MSC_SPEEDLINE = `./assets/misc/speedline.png`;
ResourceList.MSC_FADE_CIRCLE = `./assets/misc/fade_circle.png`;
ResourceList.MSC_CIRCLE = `./assets/misc/circle.png`;
ResourceList.MSC_BTN_MUTE = `./assets/misc/btn_mute.png`;
ResourceList.MSC_BTN_UNMUTE = `./assets/misc/btn_unmute.png`;
ResourceList.MSC_BTN_CLOSE = `./assets/misc/btn_close.png`;
ResourceList.MSC_TOP_BANNER = `./assets/misc/top-banner.png`;
ResourceList.MSC_TOP_BANNER_AD_1 = `./assets/misc/top-banner-ad1.png`;
ResourceList.MSC_MIST = `./assets/misc/mist.png`;
ResourceList.MSC_SPEEDUP_EFFECT = `./assets/misc/speedup-effect.png`;
ResourceList.MSC_MAGNET_EFFECT = `./assets/misc/magnet-effect.png`;
ResourceList.MSC_SPEEDUP_CART_EFFECT = `./assets/misc/speedup-cart-effect.png`;
ResourceList.MSC_CART_CLICKER = `./assets/misc/cart-click.png`;
ResourceList.MSC_CART_CLICKER_LEFT = `./assets/misc/cart-click-left.png`;
ResourceList.MSC_CART_CLICKER_CENTER = `./assets/misc/cart-click-center.png`;
ResourceList.MSC_CART_CLICKER_RIGHT = `./assets/misc/cart-click-right.png`;
ResourceList.MSC_BTN_REGLES = `./assets/misc/btn-regles.png`;

ResourceList.COUNTDOWN_1 = `./assets/countdown/count1.png`;
ResourceList.COUNTDOWN_2 = `./assets/countdown/count2.png`;
ResourceList.COUNTDOWN_3 = `./assets/countdown/count3.png`;
ResourceList.COUNTDOWN_GO = `./assets/countdown/count-go.png`;


//10 points
ResourceList.PRD_ABUELITA = `./assets/products/abuelita.png`;
ResourceList.PRD_BACHOCO = `./assets/products/bachoco.png`;
ResourceList.PRD_BREAD = `./assets/products/bread.png`;
ResourceList.PRD_CARNATION = `./assets/products/carnation.png`;
ResourceList.PRD_CLORALEX = `./assets/products/cloralex.png`;
ResourceList.PRD_CLORALEXAROMAS = `./assets/products/cloralexaromas.png`;
ResourceList.PRD_COFFEEMATE = `./assets/products/coffeemate.png`;
ResourceList.PRD_ENSUENO = `./assets/products/ensueno.png`;
ResourceList.PRD_GERBER = `./assets/products/gerber.png`;
ResourceList.PRD_KITKAT = `./assets/products/kitkat.png`;
ResourceList.PRD_LALECHERA = `./assets/products/lalechera.png`;

//20 points
ResourceList.PRD_MAGGI = `./assets/products/maggi.png`;
ResourceList.PRD_NESCAFE = `./assets/products/nescafe.png`;
ResourceList.PRD_NESCAFEDECAF = `./assets/products/nescafedecaf.png`;
ResourceList.PRD_NESQUIK = `./assets/products/nesquik.png`;
ResourceList.PRD_NESQUIKBOX = `./assets/products/nesquikbox.png`;
ResourceList.PRD_NIDO = `./assets/products/nido.png`;
ResourceList.PRD_PINOL = `./assets/products/pinol.png`;
ResourceList.PRD_PLACEHOLDER = `./assets/products/placeholder.png`;
ResourceList.PRD_RECTANGLETEMPLATE = `./assets/products/rectangletemplate.png`;
ResourceList.PRD_SALSA = `./assets/products/salsa.png`;
ResourceList.PRD_TRIX = `./assets/products/trix.png`;

ResourceList.PWUP_PRESENT = `./assets/products/present.png`;
ResourceList.PWUP_WATCH_MINUS = `./assets/products/watch-minus.png`;
ResourceList.PWUP_WATCH_PLUS = `./assets/products/watch-plus.png`;
ResourceList.PWUP_LIGHTING = `./assets/products/lighting.png`;

ResourceList.EFFECTS = `./assets/effects.json`;

/* ResourceList.SND_CAUTCH = `./assets/sound/move.mp3`;
ResourceList.SND_FAIL = `./assets/sound/match.wav`;
ResourceList.SND_SCORE = `./assets/sound/paint.wav`; */


ResourceList.CUSTOM_RESOURCES = [];

ResourceList.LIST = [
    ResourceList.BG,
    ResourceList.RULES,
    ResourceList.CART,
    ResourceList.CART_OVER,
    ResourceList.START_BUTTON,
    ResourceList.HELLO_TITLE,
    ResourceList.BAD_1,
    // ResourceList.HIT_AREA,

    ResourceList.SHELVES_PRODUCTS_BL,
    ResourceList.SHELVES_PRODUCTS_BR,
    ResourceList.SHELVES_PRODUCTS_ML,
    ResourceList.SHELVES_PRODUCTS_MR,
    ResourceList.SHELVES_PRODUCTS_TL,
    ResourceList.SHELVES_PRODUCTS_TR,
    ResourceList.SHELVE_BL,
    ResourceList.SHELVE_BR,
    ResourceList.SHELVE_ML,
    ResourceList.SHELVE_MR,
    ResourceList.SHELVE_TL,
    ResourceList.SHELVE_TR,
    ResourceList.WALL,
    ResourceList.DOOR,
    ResourceList.CONVEYOR,
    ResourceList.CONVEYOR_LIGHT_LEFT,

    ResourceList.ITEM_MAGNET,
    ResourceList.ITEM_SPEEDUP,
    ResourceList.ITEM_TIMEPLUS,
    ResourceList.ITEM_TIMEMINUS,
    // ResourceList.EFFECTS,
    ResourceList.TIMER_BASE,
    ResourceList.TIMER_PROGRESS_MIDDLE,
    ResourceList.TIMER_PROGRESS_RIGHT,
    ResourceList.TIMER_PROGRESS_ARROW,

    ResourceList.PRGS_CAPSULE_BG,
    ResourceList.PRGS_CAPSULE_FLARE,
    ResourceList.PRGS_CAPSULE_SHADOW,
    ResourceList.PRGS_DISK_EXTRA_MAGNET,
    ResourceList.PRGS_DISK_EXTRA_SPEEDUP,
    ResourceList.PRGS_DISK_TIMER,

    ResourceList.MSC_INFOBOX_BG,
    ResourceList.MSC_COIN,
    ResourceList.MSC_STAR,
    ResourceList.MSC_BTN_MUTE,
    ResourceList.MSC_BTN_UNMUTE,
    ResourceList.MSC_BTN_CLOSE,
    ResourceList.MSC_TOP_BANNER,
    ResourceList.MSC_TOP_BANNER_AD_1,
    ResourceList.MSC_MIST,
    ResourceList.MSC_SPEEDUP_EFFECT,
    ResourceList.MSC_MAGNET_EFFECT,
    ResourceList.MSC_SPEEDUP_CART_EFFECT,
    ResourceList.MSC_CART_CLICKER,
    ResourceList.MSC_CART_CLICKER_LEFT,
    ResourceList.MSC_CART_CLICKER_CENTER,
    ResourceList.MSC_CART_CLICKER_RIGHT,
    ResourceList.MSC_BTN_REGLES,


    ResourceList.COUNTDOWN_1,
    ResourceList.COUNTDOWN_2,
    ResourceList.COUNTDOWN_3,
    ResourceList.COUNTDOWN_GO,

    //Products
    ResourceList.PRD_ABUELITA,
    ResourceList.PRD_BACHOCO,
    ResourceList.PRD_BREAD,
    ResourceList.PRD_CARNATION,
    ResourceList.PRD_CLORALEX,
    ResourceList.PRD_CLORALEXAROMAS,
    ResourceList.PRD_COFFEEMATE,
    ResourceList.PRD_ENSUENO,
    ResourceList.PRD_GERBER,
    ResourceList.PRD_KITKAT,
    ResourceList.PRD_LALECHERA,
    ResourceList.PRD_MAGGI,
    ResourceList.PRD_NESCAFE,
    ResourceList.PRD_NESCAFEDECAF,
    ResourceList.PRD_NESQUIK,
    ResourceList.PRD_NESQUIKBOX,
    ResourceList.PRD_NIDO,
    ResourceList.PRD_PINOL,
    ResourceList.PRD_PLACEHOLDER,
    ResourceList.PRD_RECTANGLETEMPLATE,
    ResourceList.PRD_SALSA,
    ResourceList.PRD_TRIX,


    /*     ResourceList.SND_CAUTCH,
    ResourceList.SND_FAIL,
    ResourceList.SND_SCORE, */
    ...ResourceList.CUSTOM_RESOURCES,
];


ResourceList.PRODUCTS_10_LIST = [
    ResourceList.PRD_ABUELITA,
    ResourceList.PRD_BACHOCO,
    ResourceList.PRD_BREAD,
    ResourceList.PRD_CARNATION,
    ResourceList.PRD_CLORALEX,
    ResourceList.PRD_CLORALEXAROMAS,
    ResourceList.PRD_COFFEEMATE,
    ResourceList.PRD_ENSUENO,
    ResourceList.PRD_GERBER,
    ResourceList.PRD_KITKAT,
    ResourceList.PRD_LALECHERA
];

ResourceList.PRODUCTS_20_LIST = [
    ResourceList.PRD_MAGGI,
    ResourceList.PRD_NESCAFE,
    ResourceList.PRD_NESCAFEDECAF,
    ResourceList.PRD_NESQUIK,
    ResourceList.PRD_NESQUIKBOX,
    ResourceList.PRD_NIDO,
    ResourceList.PRD_PINOL,
    ResourceList.PRD_PLACEHOLDER,
    ResourceList.PRD_RECTANGLETEMPLATE,
    ResourceList.PRD_SALSA,
    ResourceList.PRD_TRIX

];


export default ResourceList;
