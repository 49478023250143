import * as PIXI from 'pixi.js';
import { Text } from 'pixi.js';
import GameModel, { EGameStates } from '../../model/GameModel';
import SpriteCommon from "../components/common/SpriteCommon";
import ResourceList from "../../services/ResourceList";
import gsap from "gsap";
import { AppConfig } from '../../config/AppConfig';
import ItemSprite from '../components/items/ItemSprite';
import { Cart, CartOver } from '../components/items/Cart';
import Background3D from '../components/items/Background3D';
import Countdown from '../components/CountDown';
import ScoreBallon from '../components/ScoreBallon';
import { SoundManager } from '../../services/SoundManager';
import KeyPad from '../components/KeyPad';
import EItemsID from '../../model/EItemsID';
import PanelInfo from '../components/panels/PanelInfo';
import Fireworks from '../components/effects/Fireworks';
import Utils3D from '../utils/Utils3D';
import ReglesDOM from '../doms/ReglesDOM';
import EMessages from '../../services/EMessages';
import Background from '../components/items/Background';
import DebugService from '../../services/DebugService';
import ParticleEmtter from '../components/effects/ParticleEmitter'
import EItemType from '../../model/EItemType';
import SoundData from '../../services/SoundData';
import PanelControls from '../components/panels/PanelControls';



// const { gameWidth, gameHeight } = AppConfig.settings;
// const { animationSpeed, worldSize, conveyorY, conveyorWidth, zCartPosition, zDeep} = AppConfig.settings3D;
// const { levelMaxScores, newItemDelay } = AppConfig.gameSettings;


class GameScreen extends PIXI.Container {
    /**
     * @param {PIXI.Application} app
     * @param {GameModel} gameModel
     */
    constructor(app, gameModel) {
        super();
        this.app = app;
        this.gameModel = gameModel;
        this.soundManager = new SoundManager();
        this.bgImage = new SpriteCommon(ResourceList.BG);
        this.bg = new Background();
        this.bg.anchor.set(0.5, 0);

        this.items = [];
        this.itemsCont = new PIXI.Container;
        this.mist = new SpriteCommon(ResourceList.MSC_MIST);
        this.mist.anchor.set(0.5, 0.1);
        this.mist.scale.set(1.3, 1);
        this.mist.alpha = 0.6;

        this.cart = new Cart();
        this.cartOver = new CartOver(this.gameModel);

        this.scoreBallonsCont = new PIXI.Container;
        this.keyPad = new KeyPad(gameModel);
        this.scoresPanel = new PIXI.Container;
        this.panelInfo = new PanelInfo(gameModel, this);
        this.fireworks = new Fireworks();
        this.emitter = this.initEmitter()
        this.topEmitter = this.initTopEmitter()
        this.speedUpImage = new SpriteCommon(ResourceList.MSC_SPEEDUP_EFFECT);

        this.countdown = new Countdown(this.gameModel);
        this.countdown.alpha = 1;
        this.rulesDOM = new ReglesDOM(this.gameModel);
        this.panelControls = new PanelControls(gameModel, this);

        this.initialSpeed = this.gameModel.speed * this.gameModel.speedUpFactor;
        this.t = 0;

        this.addElements = () => {
          const { gameWidth, gameHeight } = AppConfig.settings;

          this.addChild(this.bg);
          this.bg.anchor
          this.addChild(this.bgImage);
          this.addChild(this.itemsCont);
          this.addChild(this.emitter);

          this.addChild(this.cart);
          this.addChild(this.topEmitter);
          this.addChild(this.mist);

          this.cart.scale.set(1);
          this.cart.anchor.set(0.5, 1);
          this.cart.y = gameHeight;
          this.cart.x = gameWidth / 2;
          this.addChild(this.cartOver);
          this.cartOver.scale.set(1);
          this.cartOver.anchor.set(0.5, 1);
          this.cartOver.y = gameHeight;
          this.cartOver.x = gameWidth / 2;
          this.addChild(this.panelInfo);
          // this.addChild(this.fireworks);
          this.addChild(this.speedUpImage);
          this.speedUpImage.visible = false;

          this.claculateParams();

          this.addChild(this.keyPad);

          this.panelInfo.x = 10;
          this.panelInfo.y = 20;
          this.panelControls.y = 20;

          this.addChild(this.scoreBallonsCont);
          this.updateScores();
          this.updateTimeLeft();

          // this.countdown.position.set(app.screen.width / 2, app.screen.height / 2);
          this.addChild(this.countdown);
          this.countdown.on('countdownStarted', () => {
              this.acitvateSounds();
          });
          this.countdown.on('countdownComplete', () => {
              this.gameModel.sendMessage(EMessages.SND_GAME_START)
              this.start();
          });
          this.addChild(this.panelControls);

          this.onResize();
          this.switchBG();

        };

        this.count = 1;

        this.updateScores = (item, scores) => {
            const { levelMaxScores } = AppConfig.gameSettings;
            this.addScoreBallon(item, 'scores', scores);
        };

        this.updateTimeLeft = (item, timeIncrement) => {
            if (timeIncrement > 0 || timeIncrement < -10) this.addScoreBallon(item, 'time', timeIncrement);
        };

        this.onGameStateUpdated = () => {
            if (this.gameModel.gameState === EGameStates.playing){
                this.soundManager.play(SoundData.LOOP_BG_MUSIC);
                if (this.gameModel.isMagnet) this.soundManager.play(SoundData.LOOP_MAGNET);
                if (this.gameModel.speedUpFactor > 1) this.soundManager.play(SoundData.LOOP_SPEEDUPT);
            } else {
              this.soundManager.stopGameSounds();
            }

        };

        this.onCartLineUpdated = () => {
            const { gameWidth } = AppConfig.settings;

            const f = 0.5 * this.gameModel.cartLine;
            const conveyorWidth2D = gameWidth * 0.5;
            const trgX = gameWidth / 2   + conveyorWidth2D * f;

            gsap.to(this.cart, { x: trgX, duration: 1.1, ease: "elastic.out(1.2, 0.9, 1.05, 0.98)"});
            gsap.to(this.cartOver, { x: trgX, duration: 1.1, ease: "elastic.out(1.2, 0.9, 1.05, 0.98)"});

        };

        this.onExtraCoutch = (item) => {
            if (item.itemKind.id === EItemsID.SPEED_UP || item.itemKind.id === EItemsID.MAGNET){
                this.addScoreBallon(item, item.itemKind.id);
            }
        };

 
        this.gameModel.extraStatusUpdated.add((extraID, isOn) => {
          if (extraID === EItemsID.SPEED_UP) {
            if (isOn) {
              const { gameWidth, gameHeight } = AppConfig.settings;
              const { horyzontPos } = AppConfig.settings3D;

              this.topEmitter.start(gameWidth / 2, gameHeight * horyzontPos, 5, 'speedFX');
              this.soundManager.play(SoundData.LOOP_SPEEDUPT);

            } else {
              this.topEmitter.stopAll('speedFX');
              this.soundManager.stop(SoundData.LOOP_SPEEDUPT);
            }
          }

          if (extraID === EItemsID.MAGNET) {
            if (isOn) {
              this.soundManager.play(SoundData.LOOP_MAGNET);
            } else {
              this.soundManager.stop(SoundData.LOOP_MAGNET);
            }
          }

        });


        this.onSpeedUpdated = () => {
            this.reRunAddRowInterval();
        };

        this.onSteakMultiplierUpdated = () => {
            const { gameWidth, gameHeight } = AppConfig.settings;
            const { horyzontPos} = AppConfig.settings3D;
            this.bg.showHideLights(this.gameModel.steakMultiplier > 1);
            const target = { x: gameWidth / 2, y: gameHeight * horyzontPos};
            this.addScoreBallon(target, 'steakMultiplier', this.gameModel.steakMultiplier);
        };


        this.onGameRestarted = (item) => {
            this.removeAllItems();
            this.countdown.resetCountDown();
            this.presetItemsOnConveyor();
        };

        this.items = [];
        this.eventMode = `dynamic`;

        this.gameModel.scoreUpdated.add(this.updateScores);
        this.gameModel.timeLeftUpdated.add(this.updateTimeLeft);
        this.gameModel.gameStateUpdated.add(this.onGameStateUpdated);
        this.gameModel.cartLineUpdated.add(this.onCartLineUpdated);
        this.gameModel.extraCoutch.add(this.onExtraCoutch);
        this.gameModel.speedUpdated.add(this.onSpeedUpdated);
        this.gameModel.gameRestarted.add(this.onGameRestarted);
        this.gameModel.steakMultiplierUpdated.add(this.onSteakMultiplierUpdated);

        this.init();
      AppConfig.sizeUpdated.add(this.onResize.bind(this));
    }

    init() {
        this.addElements();
        this.presetItemsOnConveyor();
        this.step = 0;
        this.deltasum = 0;
        this.app.ticker.add((delta) => {
            if (this.gameModel.gameState !== EGameStates.playing) return
            this.t += delta;
            this.gameModel.updateLastMSTime(this.app.ticker.lastTime);
            const speed = this.blockSpace3Dz * (this.app.ticker.deltaMS / (this.gameModel.speed * 1000));
            // const speed = this.blockSpace3Dz * (delta / )
            this.items.forEach(c => { c.point3D.z -= speed * this.gameModel.speedUpFactor; });

            if (this.cartOver) this.cartOver.animate();
            this.panelInfo.updateExtras();
            this.fireworks.update();
            this.step++;
            this.deltasum += delta;
            if (this.step % 60 === 0) {
                const rep = Math.round(this.deltasum * 100) / 100;
                // DebugService.log(rep);
                this.deltasum = 0;
                // console.lof(Math.random());
            }

        });

        window.addEventListener('keydown', (e) => {
            if (e.code === 'KeyB') {
                this.switchBG();
            }
            // if (this.gameModel.gameState !== EGameStates.playing) return
            if (e.code === 'ArrowRight') {
                this.gameModel.registerMoveCart(false);
            } else if (e.code === 'ArrowLeft') {
                this.gameModel.registerMoveCart(true);
            };
        });

    };


  initTopEmitter() {
    const speedLineTex= PIXI.Texture.from(ResourceList.MSC_SPEEDLINE);
    const shockTexArray= new Array().fill(0).map((el, i) => {
      const link = `./assets/misc/speedline${i}.png`;
      return PIXI.Texture.from(link);
    })

    const emitter = new ParticleEmtter();

    this.speedUpEffectConfig = emitter.add('speedFX', {
      life: [1, 2],
      birthrate: 0.1,
      width: 1,
      height: 1,
      colors: [0xffffff],
      textures: [speedLineTex],
      alpha: [0.5, 0.8],
      alphaOverLife: [0, 1, 0],
      scale: [0.1, 0.3],
      scaleOverLife: [0, 5],
      oriented: true,
      velocity: 10,
      vector: new PIXI.Point(0, 0.5),
      velocityOverLife: [1, 5],
    })

    return emitter;
  }

    initEmitter() {
      const starTex= PIXI.Texture.from(ResourceList.MSC_STAR)
      const star4Tex= PIXI.Texture.from(ResourceList.MSC_STAR4)
      const dropTex= PIXI.Texture.from(ResourceList.MSC_DROP)
      const shimmerTex= PIXI.Texture.from(ResourceList.MSC_SHIMMER)
      const circleTex= PIXI.Texture.from(ResourceList.MSC_CIRCLE)
      const fadeCircleTex= PIXI.Texture.from(ResourceList.MSC_FADE_CIRCLE)
      const magnetTex= PIXI.Texture.from(ResourceList.ITEM_MAGNET)
      const lightningTex= PIXI.Texture.from(ResourceList.ITEM_SPEEDUP)
      const timeMinusTex= PIXI.Texture.from(ResourceList.ITEM_TIMEMINUS)
      const timePlusTex= PIXI.Texture.from(ResourceList.ITEM_TIMEPLUS)
      const minusTex= PIXI.Texture.from(ResourceList.MSC_MINUS)
      const plusTex= PIXI.Texture.from(ResourceList.MSC_PLUS)

      const emitter = new ParticleEmtter();
      emitter.add('stars', {
        life: [0.3, 0.7],
        width: 50,
        height: 50,
        colors: [0xffffff],
        textures: [starTex],
        scale: [0.3, 0.5],
        scaleOverLife: [1, 1.5, 0],
        velocity: 2,
        velocityOverLife: [2, 0],
        rotation: [-Math.PI, Math.PI],
        rotationVelocity: [-0.1, 0.3],
      })

      const timeConfig = emitter.add('time', {
        life: [0.3, 0.7],
        width: 50,
        height: 50,
        colors: [0xffffff],
        textures: [minusTex],
        scale: [0.5, 0.8],
        scaleOverLife: [1, 1.5, 0],
        velocity: 2,
        velocityOverLife: [2, 0],
        rotation: [-Math.PI * 0.2, Math.PI * 0.2],
        rotationVelocity: [-0.1, 0.1],
      })

      emitter.add('drops', {
        life: [0.3, 0.7],
        width: 50,
        height: 30,
        colors: [0xffffff],
        textures: [dropTex],
        scale: [0.3, 0.5],
        scaleOverLife: [1, 1.5, 0],
        velocity: 4,
        vectors: [new PIXI.Point(0, -2)],
        velocityOverLife: [2, 0],
        forceVectors: [new PIXI.Point(0, 1)],
        force: 1,
        forceOverLife: [0, 2, 2],
        rotation: [-Math.PI, Math.PI],
        rotationVelocity: [-0.2, 0.2],
      })

      emitter.add('shimmer', {
        life: [0.2, 0.4],
        width: 10 ,
        height: 10,
        colors: [0xffffff],
        textures: [shimmerTex, shimmerTex, fadeCircleTex],
        scale: [0.3, 0.6],
        scaleOverLife: [1.5,1, 1.2],
        velocity: 0,
        alphaOverLife: [0.7, 0],
        rotation: [-Math.PI, Math.PI],
        rotationVelocity: [-0.5, 0.5],
      })

      emitter.add('shimmerStars', {
        life: [0.3, 0.6],
        width: 80,
        height: 80,
        colors: [0xffffff, 0xF6E2B8, 0xF5AEC3],
        textures: [star4Tex, fadeCircleTex],
        scale: [0.3, 0.5],
        scaleOverLife: [1, 1.4, 0],
        velocity: 3,
        velocityOverLife: [2, 0.1],
        // alphaOverLife: [0.7, 0],
        rotation: [-Math.PI, Math.PI],
        rotationVelocity: [-0.5, 0.5],
        force: 2,
        forceVectors: [new PIXI.Point(0, 1)],
        forceOverLife: [0.2, 2]
      })

      emitter.add('badCircles', {
        life: [0.3, 0.6],
        width: 60,
        height: 40,
        colors: [0xE6EEF2, 0xF5B865, 0xE8231B,0xE8231B,0xF6382B, 0xFB5A45],
        textures: [circleTex],
        scale: [0.4, 0.7],
        scaleOverLife: [1, 1.5, 0],
        velocity: 3,
        velocityOverLife: [2, 0.1],
        // alphaOverLife: [0.7, 0],
        rotation: [-Math.PI, Math.PI],
        rotationVelocity: [-0.5, 0.5],
        // twirlForce: [1, 1.2],
      })

      const powerupsConfig = emitter.add('powerUps', {
        life: [0.3, 0.5],
        width: 60,
        height: 40,
        colors: [0xffffff],
        textures: [magnetTex],
        scale: [0.2, 0.4],
        scaleOverLife: [1, 1.2, 0],
        velocity: 3,
        vector: new PIXI.Point(0, -2),
        velocityOverLife: [2, 0.1],
        alpha: [0.7, 0.9],
        rotation: [-Math.PI * 0.3, Math.PI * 0.3],
        rotationVelocity: [-0.1, 0.1],
        force: 2,
        forceVectors: [new PIXI.Point(0, 2)],
        forceOverLife: [0, 2]
        // twirlForce: [1, 1.2],
      })

      this.on('burstParticles', (x, y, type) => {
        switch (type.itemType) {
          case EItemType.TIME:
                        if (type.kindness === 'good') {
                          timeConfig.textures = [plusTex]
                          emitter.start(x, y,  12, 'time');
                        }
          else {
                          emitter.start(x, y,  8, 'badCircles');
                          timeConfig.textures = [minusTex]
                          emitter.start(x, y,  10, 'time');
                        }
            break;
          case EItemType.MAGNET:
            emitter.start(x, y,  10, 'stars');
            powerupsConfig.textures = [magnetTex]
            emitter.start(x, y,  5, 'powerUps');
            break;

          case EItemType.SPEED_UP:
            emitter.start(x, y,  10, 'stars');
            powerupsConfig.textures = [lightningTex]
            emitter.start(x, y,  5, 'powerUps');
            break;

          case EItemType.SCORES:
            if (type.kindness === 'good') {
              emitter.start(x, y + 20,  8, 'shimmer');
              emitter.start(x, y + 20,  7, 'shimmerStars');
            }
         else {
              emitter.start(x, y,  10, 'drops');
            }
            break;
          default:
        }
      })

      return emitter;
    }

    acitvateSounds() {
        //we activate them on first user action
        this.soundManager.init();
    }

    start() {
        this.gameModel.resumeGame();
        this.reRunAddRowInterval();

    }

    moveToCart(item) {
        this.cart.cloneItem(item);
    };

    animate (delta = 0) {
    };

    onResize(item) {
    const {gameWidth, gameHeight} = AppConfig.settings;
    const {horyzontPos} = AppConfig.settings3D;

    this.items.forEach((item) => {
      item.updatePosByPoint3D();
    });

    this.mist.x = gameWidth / 2;
    this.mist.y = gameHeight * horyzontPos;
    this.countdown.position.set(gameWidth / 2, gameHeight / 2);

    this.cart.y = gameHeight;

    this.cartOver.y = gameHeight;


    this.onCartLineUpdated();

    this.bgImage.width = gameWidth;
    this.bgImage.height = gameHeight;

    this.bg.scale.set(this.bgImage.scale.x, this.bgImage.scale.y);

    if (this.speedUpEffectConfig && this.speedUpEffectConfig.origin) {
      this.speedUpEffectConfig.origin.x = gameWidth / 2;
      this.speedUpEffectConfig.origin.y = gameHeight * horyzontPos;
    }
  }

    reRunAddRowInterval() {
        if (this.newItemInterval){
            clearInterval(this.newItemInterval);
        }
        const currentAddItemInterval = (this.gameModel.speed * 1000) / this.gameModel.speedUpFactor;
        this.newItemInterval = setInterval(() => {
            if (this.gameModel.gameState !== EGameStates.playing) return
            const newItems = this.addItemsRow();
            this.test_checkDistance();
        }, currentAddItemInterval);
    }

    /**
     *
     * @returns {Array.<ItemSprite>}
     */
    addItemsRow() {
        const { worldSize, conveyorY, zDeep } = AppConfig.settings3D;

        const modelItemsArray = this.gameModel.getNextItemModelsRow()
        const itemsArray = []
        for (let i = 0; i < modelItemsArray.length; i++) {
            const itemModel = modelItemsArray[i];
            const item = new ItemSprite(itemModel.posInRow, itemModel.itemKind, this.gameModel, this);
            itemsArray.push(item);
            item.anchor.set(0.5, 1);
            item.outOfBoundsCallback = () => this.onItemOutOfBounds(item);
            const yPosOnConveyor = conveyorY * worldSize;
            const xPosOnConveyor = this.get3DXByPosInRow(itemModel.posInRow);
            item.point3D.setPositions(xPosOnConveyor, yPosOnConveyor, zDeep);
            item.axis3Dx = xPosOnConveyor;
            this.itemsCont.addChild(item);
            this.items.push(item);
            this.count++;
            this.sortItems();
            item.alpha = 0;
            const appearingDuration = 4 / (this.gameModel.speed * this.gameModel.speedUpFactor);
            // gsap.to(item, { alpha: 1, duration: appearingDuration, onComplete: () => { item.alpha = 1; } });
        }
        return itemsArray

    };

   sortItems() {
        this.items.sort((a, b) => {
            a.point3D.z - b.point3D.z
        });

        this.items.forEach((item, index) => {
          item.zIndex = 0xffffff - index;
        });
        this.itemsCont.sortChildren();
      }

    removeItem(item) {
        this.itemsCont.removeChild(item);
        const index = this.items.indexOf(item, 0);
        if (index > -1) {
            this.items.splice(index, 1);
        }
    };

    claculateParams() {
        const { zCartPosition, zDeep} = AppConfig.settings3D;

        const zLeng = zDeep - zCartPosition;
        const n = 10;
        this.blockSpace3Dz = zLeng / n;
    }

    presetItemsOnConveyor() {
        const { zDeep } = AppConfig.settings3D;

        const n = 10;
        for (let i = n - 1; i >= 0; i--) {
            let itemsRow = this.addItemsRow();
            for (let r = 0; r < itemsRow.length; r++) {
                let item = itemsRow[r];
                item.point3D.z = zDeep - i * this.blockSpace3Dz;
                item.axis3Dx = this.get3DXByPosInRow(item.posInRow);
            }

        }
        this.sortItems();
    };

    get3DXByPosInRow(pos) {return Utils3D.get3DXByPosInRow(pos)}

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min);
      }

    onItemOutOfBounds(item) {
        this.removeItem(item);
        item.destroy();
    }

    /**
     * @param {*} item
     * @param {( 'scores' | 'time')} item
     * @param {*} value
     */
    addScoreBallon(item, type, value) {
        const { gameWidth, gameHeight } = AppConfig.settings;

        if (item === undefined) return
        let point = item ? {x:item.x, y:item.y} : {x:gameWidth / 2, y:gameHeight / 2};
        this.fireworks.setImmiterPos(point.x, point.y);
        const pos = item?.posInRow !== undefined ? item?.posInRow : 0;
        const scoreBallon = new ScoreBallon(type, value, point, pos, item?.itemKind);
        this.scoreBallonsCont.addChild(scoreBallon);
        scoreBallon.on('finish', () => {
            this.scoreBallonsCont.removeChild(scoreBallon);
            scoreBallon.removeAllListeners('finish');
        });

        if (item?.itemKind?.id) {
            this.soundManager.playItemCautch(item.itemKind.id);
        } 
    }

    getCartXByLine(linePos) {

    }

    removeAllItems() {
        this.items.splice(0,this.items.length);
        this.itemsCont.removeChildren();
        this.cart.removeAllItems();
    }

    test_checkDistance() {
        let distances = [];
        for (let i = 0; i < this.items.length; i++){
            let item = this.items[i];
            if (i < this.items.length - 1) {
                let diff = this.items[i + 1].point3D.z - this.items[i].point3D.z
                distances.push(diff);
            }
        }
    }

    switchBG() {
        this.bgImage.visible = !this.bgImage.visible;
        this.bg.visible = !this.bgImage.visible;
    }
}
export default GameScreen;
