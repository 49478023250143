{
  "name": "basket-run",
  "version": "0.2.9",
  "description": "basket run game",
  "scripts": {
    "clear": "rm -rf dist/* && rm -rf .parcel-cache/*",
    "start": "PORT=8080 parcel src/index.html --https",
    "build": "parcel build src/index.html --public-url ./"
  },
  "author": "ushan",
  "license": "MIT",
  "dependencies": {
    "@pixi/filter-zoom-blur": "^5.1.1",
    "crypto-js": "^4.2.0",
    "gsap": "^3.6.1",
    "howler": "^2.2.4",
    "mini-signals": "^2.0.0",
    "pixi.js": "^7.3.2"
  },
  "devDependencies": {
    "@parcel/config-default": "2.9.2",
    "parcel": "^2.10.1",
    "parcel-plugin-static-files-copy": "file:../..",
    "parcel-reporter-static-files-copy": "^1.5.3",
    "punycode": "^1.4.1",
    "querystring-es3": "^0.2.1",
    "ts-loader": "^8.0.11",
    "typescript": "^4.0.5"
  },
  "staticFiles": {
    "staticPath": "src/assets",
    "staticOutPath": "assets",
    "watcherGlob": "**"
  },
  "keywords": []
}
