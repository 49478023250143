import * as PIXI from 'pixi.js';
import SpriteCommon from '../common/SpriteCommon';
import ResourceList from '../../../services/ResourceList';
import Background3D from './Background3D';
import { AppConfig } from '../../../config/AppConfig';

class Background extends SpriteCommon {
    constructor() {
        super(ResourceList.WALL);
        this.BASE_WIDTH = 375;
        this.BASE_HEIGHT = 730;
        this.wall = new SpriteCommon(ResourceList.WALL);
        this.door = new SpriteCommon(ResourceList.DOOR);
        this.conveyor = new SpriteCommon(ResourceList.CONVEYOR);
        this.conveyorLightLeft = new SpriteCommon(ResourceList.CONVEYOR_LIGHT_LEFT);
        this.conveyorLightRight = new SpriteCommon(ResourceList.CONVEYOR_LIGHT_LEFT);
        // this.wall.visible = false;

        this.shelveTL = new SpriteCommon(ResourceList.SHELVE_TL);
        this.shelveTR = new SpriteCommon(ResourceList.SHELVE_TR);
        this.shelveML = new SpriteCommon(ResourceList.SHELVE_ML);
        this.shelveMR = new SpriteCommon(ResourceList.SHELVE_MR);
        this.shelveBL = new SpriteCommon(ResourceList.SHELVE_BL);
        this.shelveBR = new SpriteCommon(ResourceList.SHELVE_BR);

        this.shelvesProductsTL = new SpriteCommon(ResourceList.SHELVES_PRODUCTS_TL);
        this.shelvesProductsTR = new SpriteCommon(ResourceList.SHELVES_PRODUCTS_TR);
        this.shelvesProductsML = new SpriteCommon(ResourceList.SHELVES_PRODUCTS_ML);
        this.shelvesProductsMR = new SpriteCommon(ResourceList.SHELVES_PRODUCTS_MR);
        this.shelvesProductsBL = new SpriteCommon(ResourceList.SHELVES_PRODUCTS_BL);
        this.shelvesProductsBR = new SpriteCommon(ResourceList.SHELVES_PRODUCTS_BR);

        // this.bg3D = new Background3D();

        this.addChild(this.wall);
        this.addChild(this.door);
        this.addChild(this.conveyor);
        this.addChild(this.conveyorLightLeft);
        this.addChild(this.conveyorLightRight);
        this.conveyorLightLeft.blendMode = PIXI.BLEND_MODES.ADD;
        this.conveyorLightRight.blendMode = PIXI.BLEND_MODES.ADD;

        this.addChild(this.shelveBL);
        this.addChild(this.shelveBR);
        this.addChild(this.shelvesProductsBL);
        this.addChild(this.shelvesProductsBR);
        this.addChild(this.shelveML);
        this.addChild(this.shelveMR);
        this.addChild(this.shelvesProductsML);
        this.addChild(this.shelvesProductsMR);
        this.addChild(this.shelveTL);
        this.addChild(this.shelveTR);
        this.addChild(this.shelvesProductsTL);
        this.addChild(this.shelvesProductsTR);


        // this.addChild(this.bg3D);

        this.wall.anchor.set(0.5, 0);
        this.door.anchor.set(0.5, 0);
        this.conveyor.anchor.set(0.5, 0);

        //for autoresize
        this.shelveTL.anchor.set(0, 0);
        this.shelveTR.anchor.set(1, 0);
        this.shelveML.anchor.set(0, 0);
        this.shelveMR.anchor.set(1, 0);
        this.shelveBL.anchor.set(0, 0);
        this.shelveBR.anchor.set(1, 0);

        this.onResize();
        this.showHideLights(false);
    }

    /**
     * @access public
     */
    onResize() {
        // const { gameWidth, gameHeight } = AppConfig.settings;
        const { horyzontPos } = AppConfig.settings3D;

        const gameWidth = 375 ;
        const gameHeight = 730;


        const line1 = 175;
        const line2 = 256;
        const line3 = 333;

        const xdisplace = 100;
        const ydisplace = 0;

        this.shelveTL.x = 0;
        this.shelveTL.y = line1;

        this.shelveTR.x = gameWidth;
        this.shelveTR.y = line1 - ydisplace;

        this.shelveML.x = 0;
        this.shelveML.y = line2;

        this.shelveMR.x = gameWidth;
        this.shelveMR.y = line2 - ydisplace;

        this.shelveBL.x = 0;
        this.shelveBL.y = line3 - ydisplace;

        this.shelveBR.x = gameWidth;
        this.shelveBR.y = line3 - ydisplace;


        this.shelvesProductsTL.x = -79;
        this.shelvesProductsTL.y = 149;

        this.shelvesProductsTR.x = 302;
        this.shelvesProductsTR.y = 146;

        this.shelvesProductsML.x = -60;
        this.shelvesProductsML.y = 219;

        this.shelvesProductsMR.x = 304;
        this.shelvesProductsMR.y = 220;

        this.shelvesProductsBL.x = -35;
        this.shelvesProductsBL.y = 306;

        this.shelvesProductsBR.x = 302;
        this.shelvesProductsBR.y = 296;

        this.wall.x = gameWidth / 2;
        this.wall.y = 0;
        this.wall.width = gameWidth;
        this.wall.height = gameHeight * 0.5;
        this.conveyor.x = gameWidth / 2;
        this.conveyor.y = 230;
        this.conveyor.scale.set(1, 1);

        this.conveyorLightLeft.x = -38;
        this.conveyorLightLeft.y = 145;

        this.conveyorLightRight.scale.set(-1, 1);
        this.conveyorLightRight.x = 410;
        this.conveyorLightRight.y = 145;

        this.door.x = gameWidth / 2;
        this.door.y = gameHeight * horyzontPos - 50;

        // this.bg3D.redraw();
    }

    setSizes() {
        const { gameWidth, gameHeight } = AppConfig.settings;
        
    }

    /**
     * @access public
     */
    showHideLights(show) {
/*         this.conveyorLightLeft.visible = show;
        this.conveyorLightRight.visible = show;  */
        this.conveyorLightLeft.alpha = show ? 1 : 0;
        this.conveyorLightRight.alpha = show ? 1 : 0;
        
    }
}

export default Background;